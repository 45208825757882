const { VUE_APP_LOGGING_API_URL } = process.env;

export const SEVERITY = {
  DEBUG: 'Debug',
  INFORMATION: 'Information',
  WARNING: 'Warning',
  ERROR: 'Error',
  FATAL: 'Fatal'
};

let prevMessageTemplateValues = [];

export const getHostName = function() {
  return window.location.hostname;
};

export const getHostUrl = function() {
  return window.location.href;
};

export const log = ({
  messageTemplate = `Error on: {host} {url} {message} {stack}`,
  messageTemplateValues = [],
  severity = SEVERITY.ERROR,
  source = 'JS'
}) => {
  // Uncomment to disable logging
  // return false;
  const token = window.localStorage.getItem('token');
  if (
    token &&
    VUE_APP_LOGGING_API_URL &&
    JSON.stringify(prevMessageTemplateValues) !== JSON.stringify(messageTemplateValues)
  ) {
    prevMessageTemplateValues = messageTemplateValues;
    fetch(`${VUE_APP_LOGGING_API_URL}/Logs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        appName: 'onboarding-app',
        appType: 'frontend',
        severity: severity,
        messageTemplate: messageTemplate,
        messageTemplateValues: messageTemplateValues,
        ipAddress: '-',
        source: `${source}`
      })
    });
  }
};

import { getHostName, getHostUrl, log } from '@/utils/logging';

export default {
  methods: {
    showSuccessNotification(message) {
      return this.showNotification('success', message, 'bottom-right');
    },
    showInfoNotification(message) {
      return this.showNotification('info', message, 'bottom-right');
    },
    showErrorNotification(error, fallbackMessage) {
      let message;
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors[0] &&
        error.response.data.errors[0].message
      ) {
        message = error.response.data.errors[0].message;
      } else if (error && error.error) {
        message = error.error;
      } else if (fallbackMessage) {
        message = fallbackMessage;
      } else if (typeof error === 'string') {
        message = error;
      } else {
        message = this.$t('notifications.error');
      }

      try {
        const messageTemplate = `Error on {host} {url} with {message} for userid: {userid} templateid: {templateid}`;
        const messageTemplateValues = [
          getHostName(),
          getHostUrl(),
          message,
          String(JSON.parse(window.localStorage.getItem('user')).id),
          window.localStorage.getItem('templateId')
        ];
        log({ messageTemplateValues: messageTemplateValues, messageTemplate: messageTemplate });
      } catch (error) {}
      return this.showNotification('error', message, 'bottom-right');
    },
    showNotification(type, message, position) {
      return this.$notify({
        type: type,
        message: message,
        position: position,
        dangerouslyUseHTMLString: true
      });
    }
  }
};
